export const tableMixin = {
  data() {
    return {
      loading: false,
      items: [],
      meta: {
        count: 0,
      },
      paging: true,
      params: {
        search_keyword: '',
      },
      pageParams: {
        page_number: 1,
        page_size: 10,
      },
      filterParams: {},
      searchParams: {
        search_type: 'all',
        search_keyword: '',
      }
    }
  },
  methods: {
    filterSubmit() {
      this.pageParams.page_number = 1
      this.getData()
    },
    tableBinding() {
      return {
        data: this.items,
        totalCount: this.meta.count,
        'page': this.pageParams.page_number,
        'hide-paginator': !this.paging,
      }
    },
    getData() {
      this.loading = true
      this.$axios.$get(this.apiUrl, {params: this.getQueryParams()}).then(res => {
        this.items = res.result
        this.meta = res.meta
      }).finally(_ => {
        this.loading = false
      })
    },
    pageChange(num) {
      this.pageParams.page_number = num
      this.getData()
    },
    getQueryParams() {
      let params = this._.assign(this.params, this.filterParams)
      if (this.paging) {
        params = this._.assign(params, this.pageParams)
      }
      if (this.searchParams) {
        params = this._.assign(params, this.searchParams)
      }

      return params
    },
    onDelete(id) {
      this.deleteConfirm(() => {
        this.loading = true
        this.$axios.delete(`${this.apiUrl}/${id}`).then(res => {
          this.getData()
        }).finally(() => {
          this.loading = false
        })
      })
    },
    onSearch() {
      this.filterSubmit()
    }
  },
}